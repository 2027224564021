import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text
} from '@chakra-ui/react';
import { BrandCourseCategoryCard } from '../BrandCourseCategoryCard/BrandCourseCategoryCard';
import { BrandCourseCategoryCardRemarksOnly } from '../BrandCourseCategoryCard/BrandCourseCategoryCardRemarksOnly/BrandCourseCategoryCardRemarksOnly';
import {
  CourseCategory,
  ThemeColors
} from '@/features/brand/types/brand';

export type SpBrandCourseAccordionProps = {
  courseCategoryCard: CourseCategory;
  themeColors: ThemeColors;
  isOption?: boolean;
};


export const SpBrandCourseAccordion = ({
  courseCategoryCard,
  themeColors,
  isOption
}: SpBrandCourseAccordionProps) => {
  const {
    specialCourseCategory,
    courseCategoryName
  } = courseCategoryCard;
  const {
    brandColor = 'reraku.500',
    brandBgColor,
    brandBgSubColor,
  } = themeColors;

  return (
    <AccordionItem
      backgroundColor={brandBgColor}
      my={1}
      borderBottomLeftRadius={10}
      borderBottomRightRadius={10}
    >
      {({ isExpanded }) =>
        <>
          <AccordionButton
            backgroundColor={isExpanded ? brandColor : brandBgSubColor}
            color={isExpanded ? 'white' : 'brandSecondary.500'}
            height={'50px'}
            _focus={{ outline: 'none' }}
            _hover={{ backGroundColor: brandColor }}
          >
            <Box
              flex={1}
              textStyle={'h3'}
              textAlign={'left'}
              fontWeight={'bold'}
              display={'flex'}
            >
              {specialCourseCategory && specialCourseCategory.headingDecoration}
              <Text ml={specialCourseCategory ? 1 : 0}>{courseCategoryName}</Text>
            </Box>
            <AccordionIcon
              css={
                isExpanded ?
                  { WebkitTransform: 'rotate(0deg)' }
                  :
                  { WebkitTransform: 'rotate(-90deg)' }
              }
            />
          </AccordionButton>
          <AccordionPanel
            pb={4}
          >
            {courseCategoryCard.courseCategoryDetails ? (
              <BrandCourseCategoryCard
                courseCategoryCard={courseCategoryCard}
                themeColors={themeColors}
              />) : (
              <BrandCourseCategoryCardRemarksOnly
                courseCategoryCard={courseCategoryCard}
                themeColors={themeColors}
                isOption={isOption}
              />
            )}
          </AccordionPanel>
        </>
      }
    </AccordionItem>
  );
};
