import {
  Box,
  HStack,
  Text
} from '@chakra-ui/react';

export type HourlyCourseItemProps = {
  time?: number;
  price?: number;
  description?: string;
  courseTextColor?: string;
}

export const HourlyCourseItem = ({
  time,
  price,
  description,
  courseTextColor,
}: HourlyCourseItemProps) => {
  return (
    <Box
      position={'relative'}
      bg={{ lg: 'gray.200' }}
      mr={{ base: 0, lg: 10 }}
      ml={{ base: 0, lg: 3 }}
      py={3}
      px={{ lg: 3 }}
      _first={{ mt: { base: 0, lg: 10 } }}
      _last={{
        mb: 5,
        pb: { base: 0, lg: 6 }
      }}
      _notLast={{
        _after: {
          content: '""',
          position: 'absolute',
          display: 'inlineBlock',
          width: { base: '93%', lg: '88%' },
          mt: '11px',
          mx: '12px',
          height: '1px',
          backgroundColor: 'gray.300',
        }
      }}
    >
      <Box
        mx={4}
      >
        <Box>
          <HStack
            alignItems={'baseline'}
            justifyContent={'space-between'}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              pb={2}
            >
              <>
                {time &&
                  <Text
                    color={courseTextColor}
                    fontWeight={'bold'}
                    textStyle={'h1'}
                  >
                    {time}
                    <Text
                      as={'span'}
                      textStyle={'h5'}
                    >分
                    </Text>
                  </Text>}
              </>
            </Box>
            <Box>
              {price &&
                <Text
                  color={courseTextColor}
                  textStyle={'h3'}
                  fontWeight={'bold'}
                  textAlign={'left'}
                  pl={3}
                >
                  &yen; {price.toLocaleString('ja-JP')}
                  <Text
                    as={'span'}
                    textStyle={'h10'}
                    fontWeight={'normal'}
                    pl={1}
                  >
                    (税込)
                  </Text>
                </Text>
              }
            </Box>
          </HStack>
        </Box>
        <HStack
          alignItems={'baseline'}
        >
          <Box>
            <Text
              textStyle={'h6'}
              whiteSpace={'pre-line'}
              textAlign={'justify'}
            >
              {description}
            </Text>
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};
