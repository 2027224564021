import {
  Box,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react';
import { useState } from 'react';
import { Image } from '@/components/Image';
import {
  CourseCategory,
  ThemeColors
} from '@/features/brand/types/brand';

export type PcBrandOptionModalProps = {
  courseCategoryCard: CourseCategory;
  themeColors: ThemeColors;
}

export const PcBrandOptionModal = ({
  courseCategoryCard: {
    courseCategoryImageUrl,
    courseCategoryName,
    courseCategoryDescription,
    courseCategoryDetails,
    specialCourseCategory
  },
  themeColors: {
    courseTextColor,
  }
}: PcBrandOptionModalProps) => {

  const [selectedOption, setSelectedOption] = useState<string>('');
  const onOpenOptionModal = (opt: string) => {
    setSelectedOption(opt);
  };
  const onCloseOption = () => {
    setSelectedOption('');
  };
  return (
    <>
      <Box
        onClick={() => onOpenOptionModal(courseCategoryName)}
        cursor={'pointer'}
        _hover={{ opacity: 0.8 }}
        w={'26%'}
      >
        <Box
          mr={3}
          mb={3}
        >
          <Image
            src={courseCategoryImageUrl}
            alt={courseCategoryName}
            width={300}
            height={200}
          />
          <Box
            color={specialCourseCategory? specialCourseCategory.specialTextColor : courseTextColor}
            fontWeight={'bold'}
            textStyle={'h4'}
            display={'flex'}
            alignItems={'center'}
            pt={1}
          >
            {specialCourseCategory && specialCourseCategory.headingDecoration}
            <Text ml={specialCourseCategory ? 2 : 0}>{courseCategoryName}</Text>
          </Box>
        </Box>
      </Box>
      <Modal
        isOpen={courseCategoryName === selectedOption}
        onClose={onCloseOption}
        isCentered={true}
      >
        <ModalOverlay/>
        <ModalContent>
          <ModalCloseButton
            _focus={{ outline: 'none' }}
          />
          <ModalBody>
            <ModalHeader/>
            <Image
              src={courseCategoryImageUrl}
              alt={courseCategoryName}
              width={400}
              height={267}
            />
            <Box
              my={3}
            >
              <Box
                textStyle={'h3'}
                color={specialCourseCategory? specialCourseCategory.specialTextColor : courseTextColor}
                fontWeight={'bold'}
                display={'flex'}
              >
                {specialCourseCategory && specialCourseCategory.headingDecoration}
                <Text ml={2}>{courseCategoryName}</Text>
              </Box>
              <Box
                textStyle={'h7'}
                whiteSpace={'pre-line'}
              >
                {courseCategoryDescription}
              </Box>
              <Flex
                justifyContent={'space-evenly'}
                py={3}
              >
                {courseCategoryDetails && courseCategoryDetails.map(({
                  time,
                  price
                }, index) =>
                  <HStack
                    alignItems={'baseline'}
                    key={index}
                    _notLast={{
                      borderRight: '1px solid',
                      borderColor: 'gray.300',
                      pr: 5
                    }}
                  >
                    <Text
                      color={specialCourseCategory? specialCourseCategory.specialTextColor : courseTextColor}
                      fontWeight={'bold'}
                      textStyle={'h3'}
                    >
                      {time}
                      <Text
                        as={'span'}
                        textStyle={'h5'}
                      >分
                      </Text>
                    </Text>
                    <Text
                      color={specialCourseCategory? specialCourseCategory.specialTextColor : courseTextColor}
                      textStyle={'h6'}
                      fontWeight={'bold'}
                      textAlign={'left'}
                      pl={3}
                    >
                      &yen; {price?.toLocaleString('ja-JP')}
                      <Text
                        as={'span'}
                        textStyle={'h9'}
                        fontWeight={'normal'}
                        pl={1}
                      >
                        (税込)
                      </Text>
                    </Text>
                  </HStack>
                )}
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
